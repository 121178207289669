import React from 'react'
import { Wrapper, Title } from './style'

const Intro = props => 
  <Wrapper {...props} >
    <Title>
      Hey 👋 It's Saad — another <a target="_blank" rel="noopener noreferrer" href="https://www.16personalities.com/profiles/416e528ab3f0a">INTJ-T</a> simpleton obsessing over (decentralized) softwares, (distributed) systems & (data-driven) solutions. I write <span>smart</span> stupid codes to build simple products that solve <span>simple</span> complex problems.<br/>

      Currently, <b>'buidling'</b> web3 protocols, projects, people & everything in between.<br/>
    </Title>
  </Wrapper>

export default Intro
