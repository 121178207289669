import React from 'react'
import { ListItem, BottomDottedItem } from '../../style/shared'

const ProfileListItem = ({ name, url }) =>
  <ListItem>
    {url 
      ? <a href={url} target="_blank" rel="noopener noreferrer"><BottomDottedItem>{name}</BottomDottedItem></a>
      : name}
  </ListItem>

export default ProfileListItem
